.footer{
    width: 100%;
    position: relative;
    padding: 2rem 0;
    display:flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    height: 100% ;

    // .overlay{
    //     position: absolute;
    //     height: 112.3vh;
    //     width: 100%;
    //     top: 0;
    //     bottom: 0;
    //     background: rgb(48, 61, 66);
    //     z-index: 1;
    //     mix-blend-mode: hard-light;
    // }

    .imageDiv{
        position: absolute;
        height: 100%;
        width: 100%;
        bottom: 0;

        .img{
            height: 100%;
            object-fit: cover;
        }

        // &::after{
        //     content: '';
        //     position: absolute;
        //     background: rgba(10,84,107,0.307);
        //     top: 0;
        //     bottom: 0;
        //     right: 0;
        //     left: 0;
        //     mix-blend-mode: multiply;
        // }
    }

    .secContent{
        width: 100%;
        height: max-content;
        padding: 2rem initial;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: auto;
        row-gap: 2rem;
        z-index: 100;

        .contactDiv{
            justify-content: space-between;
            flex-direction: column;
            gap: 1rem;

            .text{
                small{
                    font-size: 13px;
                    font-weight: 400;
                    color: hsl(0,0%,100%);
                }
                h2{
                    color: hsl(0,0%,100%);
                    font-size: 1.8rem;
                }
            }
            .inputDiv{
                width: 100%;
                gap: 1rem;
                flex-direction: column;

                input{
                    width:100%;
                    padding: .5rem .6rem;
                    border-radius: 3rem;
                    border: 1px solid hsl(0,0%,100%);
                    outline: none;
                    background: transparent;
                    color:   hsl(0,0%,100%);

                    &::placeholder{
                        color: hsl(0,0%,100%);
                        opacity: .5rem;
                    }
                }

                .btn{
                    width: 100%;
                    justify-content: center;
                    color: hsl(0,0%,100%);
                    font-weight: 500;
                    gap: .5rem;

                    .icon{
                        font-size: 18px;
                    }
                }
            }
        }

        .footerCard{
            position: absolute;
            padding: 0rem 1rem 0rem;
            margin-bottom: 1rem;
            gap: 1rem;
            border-radius: 0rem;
            //background: rgb(225,225,235);
            background: black;
            width: 100%;
            //height: 55vh;
            justify-content: center;
            flex-direction: column;
            align-items: flex-start;
            overflow: hidden;
            //height: 70vh;

            .footerIntro{
               flex-basis: 100%;
               justify-content: center;
               align-items: flex-start;
               //justify-items: center;
               flex-direction: column;
               row-gap: .5rem;
               padding-top: 1rem; 
               //height: 100vh;
            

            .logoDiv{
                .logo{
                    //color: hsl(201,33%,16%);
                    color: antiquewhite;
                    font-weight: 600;
                    display: block;
                    font-size: 19px;

                    .icon{
                        font-size: 25px;
                        color: hsl(199,100%,33%);
                        margin-right: 10px;
                    }
                }
            }

            .footerParagraph{
                p{font-size: 15px;
                //color: hsl(240,4%,36%);
                color: antiquewhite;
                 width: 90%;}
            }

            .footerSocials{
                column-gap: .5rem;
                
                .icon{
                    //color: hsl(201,33%,16%);
                    color: antiquewhite;
                    //background: color( red green blue);
                    font-size: 20px;

                    &:hover{
                        color: hsl(199,100%,33%);
                    }
                }
            }
        }

            .footerLinks{
                display: flex;
                flex-direction: row;
                column-gap: .5rem;
                padding-top: 1rem;
                margin-bottom: 2.5rem; 
                flex-basis: 50%;
                width: 100%;
                gap: 1rem;
                grid-template-columns: repeat(2,1fr);
                justify-content: space-between;

                .linkGroup{
                  .groupTitle{
                    display: block;
                    font-weight: 600;
                    //color: hsl(201,33%,16%);
                    color: antiquewhite;
                    margin-bottom: .5rem;
                  }
                  
                  
                  .footerList{
                    font-size: 15px;
                    //color: hsl(240,4%,36%);
                    color: antiquewhite;
                    transition: .3s ease-in-out;

                    .icon{
                        font-size: 15px;
                        //color: rgb(190,190,190);
                        color: antiquewhite;
                    }

                    &:hover{
                        color: hsl(199,100%,33%);
                        transform: translateX(7px);

                        .icon{
                            color: hsl(187,85%,43%);
                        }
                    }
                  }
                  
                }
                .linkGroup1{
                    margin-right: 0rem;
                    .groupTitle{
                      display: block;
                      font-weight: 600;
                      //color: hsl(201,33%,16%);
                      color: antiquewhite;
                      margin-bottom: .5rem;
                    }
                    .footerList1{
                        font-size: 15px;
                        //color: hsl(240,4%,36%);
                        color: antiquewhite;
                        
                        //transition: .3s ease-in-out;
                        //margin-bottom: 7px;
                        p{
                            //margin-top: 1rem;
                            margin-bottom: .3rem;
                        }
    
                      }
                }
            }
            

            .footerDiv{
                flex-direction: column;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                //background: hsl(187,85%,43%);
                background: hsl(189, 15%, 25%);
                padding: .5rem 2rem;
                //text-align: center;
                //justify-content: center;
                //align-items: center;

                small{
                    //text-align: center;
                    font-size: 10px;
                    color: hsl(0,0%,100%);
                }
            }
        }
    }
}

@media screen and (min-width:500px) {
   .footer{
    .secContent{
        .footerCard{
            .footerLinks{
                grid-template-columns: repeat(3,1fr);
                padding-bottom: 1rem;
            }
            .footerDiv{
                //justify-content: space-between;
                //flex-direction: row;
                text-align: center;
                small{
                    font-size: 14px;
                    color: hsl(0,0%,100%) ;
                }
            }
        }
    }
   } 
}

@media screen and (min-width:660px) {
    .footer{
     .secContent{
         .contactDiv{
            flex-direction: row;
            justify-content: space-between;

            .inputDiv{
                width: max-content;
                flex-direction: row;
                justify-content: flex-start;

                input{
                    width: 60%;
                }

                .btn{
                    width: max-content;
                }
            }
         }
     }
    } 
 }

 @media screen and (min-width:840px) {
    .footer{
        padding: 5rem 0;
     .secContent{
         .footerCard{
            flex-direction: row;

            .footerLinks{
                grid-template-columns: repeat(3,1fr);
                padding-bottom: 1rem;
            }
         }
     }
    } 
 }
 @media screen and (min-width:1024px) {
    .footer{
     .secContent{
        
         .footerCard{
            padding: 0rem 1rem;
            margin-top: 100px;
            
            .footerIntro{
                
                .footerParagraph{
                    font-size: 15px;
                    color: hsl(240,4%,36%);
                }
                .footerSocials{
                    column-gap: .5rem;
                    .icon{
                        color: hsl(240,4%,36%);
                        font-size: 25px;
                        &:hover{
                            color: hsl(199,100%,33%);
                        }
                    }
                }
            }


            .footerLinks{
                .linkGroup{
                    .footerList{
                        font-size: 16px;
                        padding: 5px 0;
                    }
                }
                .linkGroup1{
                    margin-right: 4rem;
                    .groupTitle{
                        margin-bottom: .8rem;
                    }
                }
            }
         }
     }
    } 
 }