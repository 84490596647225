.products{
    // width: 100%;
    // //position: relative;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // margin: auto;
    .overlay{
        position: absolute;
        height: 80vh ;
        width: 100%;
        top: 0;
        bottom: 0;
        background: rgb(48, 61, 66);
        z-index: 1;
        mix-blend-mode: hard-light;
    }
    .imageContainer{
        //bottom: 0;
        top: 0;
        width: 100%;
    height: 80vh;
    position: relative;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .partText{
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        color: hsl(0,0%,93%);
        text-align: center;
        z-index: 100;

        h1{
            font-size: 3rem;
            font-weight: 600;
            //background: #fff;
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
        }
        p{
            font-size: 1.3rem;
            
            //color: #fff;
            padding: 0.5rem 0 2rem 0;
        }
    }}
    .fromContainer{
        margin: 4rem 6rem;
        color: #2a2a2a;
        h1{
            font-size: 40px;
        }

    //     form{
    //         padding-top: 3rem;
    //         display: flex;
    //         flex-direction: column;
    //         width: 50%;
    //         margin: auto;
    //     }

    //     input{
    //         height: 3rem;
    //         padding: 0 1rem;
    //         margin-bottom: 2rem;
    //         border-radius: .3rem;
    //         border: 1px solid #2a2a2a;
    //     }

    //     textarea{
           
    //         padding:  1rem;
    //         margin-bottom: 2rem;
    //         border-radius: .3rem;
    //         border: 1px solid #2a2a2a;

    //     }

        .btn{
            margin-top: 1rem;
            margin-bottom: 1rem;
            color: hsl(0,0%,100%);
            font-weight: 600;
            justify-content: center;
            gap: .5rem;
            width: 50%;
            align-items: center;
            margin-left: 25%;
        }
    }

}
@media screen and (max-width:850px) {
    .products{
        // .overlay{
        //     height: 80vh;
        // }
        // .imageContainer{
        //     height: 80vh;
        // }
        width: min-content;
        .overlay{
            position: absolute;
            height: 40vh;
            width: 100%;
            top: 0;
            bottom: 0;
            background: rgb(48, 61, 66);
            z-index: 1;
            mix-blend-mode: hard-light;
        }
        .imageContainer{
            top: 0;
            width: 100%;
        height: 40vh;
        position: relative;
        .partText{
            h1{
                margin-top: 2rem;
                font-size: 30px;
            }
            p{
                width: 80%;
                font-size: 19px;
                margin-left: 2rem;
            }
        }
        }
        .fromContainer{
        margin: 4rem 0rem;
        height: 85vh;
        h1{
            margin: 1rem;
            font-size: 20px;
        }
        #form{
            //align-items: center;
            margin: 1rem 2rem;
            padding-bottom: 1rem;
            width: 295px;
            height: 60vh;
            //width:50%;
            //margin:'auto',
            padding:40;
            margin-top:20;
            padding-top:40;
            box-shadow:0px 0px 10px rgba(0,0,0,0.5);

            TextField{
                width: 20px;
            }
        }
        
        // form{
        //     padding-top: 2rem;
        //     width: 90%;
        //     //justify-content: center;
            

        //     .btn{
        //         width: 70%;
        //         // right: 100px;
        //     }
        // }
    }
}
}