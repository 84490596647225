.home{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    .overlay{
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        bottom: 0;
        background: rgb(55, 58, 59);
        z-index: 1;
        mix-blend-mode: hard-light;
    }

    video{
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        bottom: 0;
        object-fit: cover;
    }
    .homeContent{
        width: 100%;
        height: 40vh;
        padding: 8rem 1.5rem 3rem;
        display: flex;
        row-gap: 3rem;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        margin: auto;
        
        z-index: 100;

        .textDiv{
            margin-top: -5rem;

    
            .smallText{
                position: absolute;
                font-size: 13px;
                text-transform: uppercase;
                color: hsl(0,0%,93%);
            }
            .homeTitle{
                padding: 20px 0;
                position: absolute;
                font-size: 1.8rem;
                color: rgb(48, 142, 161);
            }
        }
    }
    
}

@media screen and (min-width:500px) {
    .home{
        .homeContent{
            .textDiv{
                .smallText{
                    font-size: 16px;
                }
                .homeTitle{
                    position: relative;
                    font-size: 2.3rem;
                    width: max-content;

                    // &::after{
                    //     position: absolute;
                    //     content: '';
                    //     background: hsl(187,85%,43%);
                    //     height: 5px;
                    //     width: 145px;
                    //     right: 0px;
                    //     bottom: 5px;
                    //     z-index: -1;
                    // }
                }
            }
        }
    }
}

@media screen and (min-width:940px) {
    .home{
        height: 70vh;
    }
}

@media screen and (min-width:1240px) {
    .home{
        height: 80vh;

        .homeContent{
            padding-left: 0;
            padding-right: 0;
        }
    }
}