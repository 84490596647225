.partners .overlay {
  position: absolute;
  height: 80vh;
  width: 100%;
  top: 0;
  bottom: 0;
  background: rgb(48, 61, 66);
  z-index: 1;
  mix-blend-mode: hard-light;
}
.partners .imageContainer {
  width: 100%;
  height: 80vh;
  position: relative;
}
.partners .imageContainer img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.partners .imageContainer .partText {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  color: hsl(0, 0%, 93%);
  text-align: center;
  z-index: 100;
}
.partners .imageContainer .partText h1 {
  font-size: 3rem;
  font-weight: 600;
}
.partners .imageContainer .partText p {
  font-size: 1.3rem;
  padding: 0.5rem 0 2rem 0;
}
.partners .aboutContainer .partText {
  margin: 4rem 6rem;
}
.partners .aboutContainer .partText .first {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4.5rem;
}
.partners .aboutContainer .partText .first .contain {
  width: 45%;
  text-align: center;
}
.partners .aboutContainer .partText .first .contain h1 {
  padding-bottom: 1rem;
}
.partners .aboutContainer .partText .first .contain p {
  padding-bottom: 2rem;
  font-size: 20px;
}
.partners .aboutContainer .partText .first img {
  width: 50%;
  height: 350px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 6px;
  box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
}
.partners .aboutContainer .partText .second {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin-top: 4.5rem;
}
.partners .aboutContainer .partText .second .contain {
  width: 45%;
  text-align: center;
}
.partners .aboutContainer .partText .second .contain h1 {
  padding-bottom: 1rem;
}
.partners .aboutContainer .partText .second .contain p {
  padding-bottom: 2rem;
  font-size: 20px;
}
.partners .aboutContainer .partText .second img {
  width: 50%;
  height: 350px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 6px;
  box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
}
.partners .aboutContainer .partText .third {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4.5rem;
}
.partners .aboutContainer .partText .third .contain {
  width: 45%;
  text-align: center;
}
.partners .aboutContainer .partText .third .contain h1 {
  padding-bottom: 1rem;
}
.partners .aboutContainer .partText .third .contain p {
  padding-bottom: 2rem;
  font-size: 20px;
}
.partners .aboutContainer .partText .third img {
  width: 50%;
  height: 350px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 6px;
  box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
}

@media screen and (max-width: 550px) {
  .partners .overlay {
    position: absolute;
    height: 40vh;
    width: 100%;
    top: 0;
    bottom: 0;
    background: rgb(48, 61, 66);
    z-index: 1;
    mix-blend-mode: hard-light;
  }
  .partners .imageContainer {
    top: 0;
    width: 100%;
    height: 40vh;
    position: relative;
  }
  .partners .imageContainer .partText h1 {
    margin-top: 2rem;
    font-size: 30px;
  }
  .partners .imageContainer .partText p {
    width: 80%;
    font-size: 19px;
    margin-left: 2rem;
  }
  .partners .aboutContainer {
    margin: 0 2rem;
    margin-bottom: 13rem;
  }
  .partners .aboutContainer .partText .first {
    margin-top: -3rem;
    display: flex;
    flex-direction: column;
  }
  .partners .aboutContainer .partText .first .contain {
    width: 330px;
    text-align: center;
  }
  .partners .aboutContainer .partText .first .contain h1 {
    margin-top: 20%;
    padding: 10px 20px;
  }
  .partners .aboutContainer .partText .first .contain p {
    font-size: 1.1rem;
    padding: 0 0 2rem 0;
  }
  .partners .aboutContainer .partText .first img {
    width: 270px;
  }
  .partners .aboutContainer .partText .second {
    margin-top: -2rem;
    display: flex;
    flex-direction: column;
  }
  .partners .aboutContainer .partText .second .contain {
    width: 330px;
  }
  .partners .aboutContainer .partText .second .contain h1 {
    margin-top: 20%;
    padding: 10px 20px;
  }
  .partners .aboutContainer .partText .second .contain p {
    font-size: 1.1rem;
    padding: 0 0 2rem 0;
  }
  .partners .aboutContainer .partText .second img {
    width: 270px;
  }
  .partners .aboutContainer .partText .third {
    margin-top: -2rem;
    display: flex;
    flex-direction: column;
  }
  .partners .aboutContainer .partText .third .contain {
    width: 330px;
  }
  .partners .aboutContainer .partText .third .contain h1 {
    margin-top: 20%;
    padding: 10px 20px;
  }
  .partners .aboutContainer .partText .third .contain p {
    font-size: 1.1rem;
    padding: 0 0 2rem 0;
  }
  .partners .aboutContainer .partText .third img {
    width: 270px;
  }
}/*# sourceMappingURL=partners.css.map */