.header{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: hsl(0,0%,93%);
    width: 100%;
    padding: 1rem;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(2,15,29,0.904);

    .logodiv{
        color: hsl(201,33%,16%);
    h1{
        //width: 2rem;
        span{
        //color: linear-gradient(to right,hsl(187,85%,43%),hsl(199,100%,33%));
        color: rgb(12, 149, 208);
    }}
    }
    @media screen and (max-width:768px) {
        
        .navBar{
            position: absolute;
        background: hsl(0,0%,93%);
        height:max-content;
        width:80%;
        border-radius: 1rem;
        top: -500rem;
        left: 50%;
        transform: translate(-50%);
        z-index: 2000;
        box-shadow: 0 2px 4px rgba(2,15,29,0.904);
        transition: .5s ease-in-out;

        .navLists{
            flex-direction: column;
            justify-content: center;
            width: 100%;
            margin: auto;
            padding: .5rem 0;

            .navItem{
                padding: .5rem 0;

                .navLink{
                    color: hsl(240, 4%, 36%);
                    font-size: .9rem;
                    font-weight: 600;

                    
                }
                

            }
            


            
        }

        .closeNavbar{
            position: absolute;
            top: 1rem;
            right: 1.5rem;
            color: hsl(199,100%,33%);
            &:hover{
                color: hsl(187,85%,43%);
            }
        }
        
        }
        
        .activeNavbar{
            top: 7rem;
        }
    }
    

    .toggleNavbar{
        .icon{
            font-size: 25px;
            color: hsl(199,100%,33%);
        }
        &:hover{
            color: hsl(187,85%,43%);
        }
    }

}

@media screen and (min-width:769px) {
    .toggleNavbar,.closeNavbar{
        display: none;
    }

    .header{
         padding: 1.5rem 2rem;

         .navBar{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            
            .navItem{
                display: inline-block;
                .navLink{
                    color: hsl(240, 4%, 36%);
                    padding: 0 0.45rem;
                    font-size: 0.9rem;
                    font-weight: 500;
                    display: block;

                    &:hover{
                        color: hsl(199,100%,33%);
                        
                    }
                    
                }
                .services-sub{
                    width: 6rem;
                    margin: 6px;
                    //left: 0;
                    top: 77px;
                    display: none;
                    position: relative;
                    list-style: none;
                    text-align: start;
                    .submenu{
                        cursor: pointer;
                        background: hsl(0, 5%, 83%);
                        
                    }
                    
                    .navLink1{
                        color: hsl(240, 4%, 36%);
                        font-size: .9rem;
                        font-weight: 600;
                        display:block ;
                        width: 50%;
                        height: 100%;
                        padding: 16px;
                        
                    }
                }
                &:hover {
                    >.services-sub{
                        display: block;
                    }
                }
            }
            
         }
        }
         
    
        
    
    
}

@media screen and (min-width: 960px) {
    .header{
        .navBar{
            .navLists{
                .navItem{
                    padding: 0 0.7rem;
                }
            }
        }
    }
}
@media screen and (min-width: 1240px) {
    .header{
        padding: 1rem 8.5rem;
    }
}