.products .overlay {
  position: absolute;
  height: 80vh;
  width: 100%;
  top: 0;
  bottom: 0;
  background: rgb(48, 61, 66);
  z-index: 1;
  mix-blend-mode: hard-light;
}
.products .imageContainer {
  top: 0;
  width: 100%;
  height: 80vh;
  position: relative;
}
.products .imageContainer img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.products .imageContainer .partText {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  color: hsl(0, 0%, 93%);
  text-align: center;
  z-index: 100;
}
.products .imageContainer .partText h1 {
  font-size: 3rem;
  font-weight: 600;
}
.products .imageContainer .partText p {
  font-size: 1.3rem;
  padding: 0.5rem 0 2rem 0;
}
.products .fromContainer {
  margin: 4rem 6rem;
  color: #2a2a2a;
}
.products .fromContainer h1 {
  font-size: 40px;
}
.products .fromContainer .btn {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: hsl(0, 0%, 100%);
  font-weight: 600;
  justify-content: center;
  gap: 0.5rem;
  width: 50%;
  align-items: center;
  margin-left: 25%;
}

@media screen and (max-width: 850px) {
  .products {
    width: -moz-min-content;
    width: min-content;
  }
  .products .overlay {
    position: absolute;
    height: 40vh;
    width: 100%;
    top: 0;
    bottom: 0;
    background: rgb(48, 61, 66);
    z-index: 1;
    mix-blend-mode: hard-light;
  }
  .products .imageContainer {
    top: 0;
    width: 100%;
    height: 40vh;
    position: relative;
  }
  .products .imageContainer .partText h1 {
    margin-top: 2rem;
    font-size: 30px;
  }
  .products .imageContainer .partText p {
    width: 80%;
    font-size: 19px;
    margin-left: 2rem;
  }
  .products .fromContainer {
    margin: 4rem 0rem;
    height: 85vh;
  }
  .products .fromContainer h1 {
    margin: 1rem;
    font-size: 20px;
  }
  .products .fromContainer #form {
    margin: 1rem 2rem;
    padding-bottom: 1rem;
    width: 295px;
    height: 60vh;
    padding: 40;
    margin-top: 20;
    padding-top: 40;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }
  .products .fromContainer #form TextField {
    width: 20px;
  }
}/*# sourceMappingURL=products.css.map */