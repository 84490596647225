.hotel{
    width: 100%;

    .contain{
        h1{
        color: hsl(201,33%,16%);
        margin-top: 10px;
        text-align: center;
    }
    .image{
        margin-top: 20px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    }
    .text{
        margin-top: 1rem;
        font-size: 20px;
    }}
    .mainCon{
        .secTitle{
            margin: 2rem 0;
        .title{
            position: relative;
            width: max-content;
            color: hsl(240,4%,36%);
            margin: 2rem 0;
            z-index: 2;
            font-weight: 600;
            font-size: 30px;

            // &::after{
            //     position: absolute;
            //     content:'';
            //     background: hsl(187,85%,43%);
            //     height: 5px;
            //     width: 10px;
            //     right: 0;
            //     bottom: 5px;
            //     z-index:-1;
            // }
        }
    }}


}

@media screen and (max-width:500px) {
    .hotel{
        width: 100%;
        margin-right: 2rem;
        margin-left: 0rem;
        .mainCon{
            margin-left: 0rem ;
            margin-right: 5rem;
            margin-bottom: 11rem;

            .grid{
                row-gap: 0rem;
                .card{
                margin-left: 3rem;
                height: 60%;
                    width: 100%;
                .cardM{
                    height: 50%;
                    width: 100%;
                }
            }}
        }
    }
}