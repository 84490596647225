.mobile {
  width: 100%;
}
.mobile h1 {
  color: hsl(201, 33%, 16%);
  margin-top: 10px;
  text-align: center;
}
.mobile .image {
  margin-top: 20px;
  box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
}
.mobile .text {
  margin-top: 1rem;
  font-size: 20px;
}

@media screen and (max-width: 500px) {
  .mobile .contain {
    margin-bottom: 10rem;
  }
}/*# sourceMappingURL=mobile.css.map */