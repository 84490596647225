.survilience .overlay {
  position: absolute;
  height: 80vh;
  width: 100%;
  top: 0;
  bottom: 0;
  background: rgb(48, 61, 66);
  z-index: 1;
  mix-blend-mode: hard-light;
}
.survilience .imageContainer {
  top: 0;
  width: 100%;
  height: 80vh;
  position: relative;
}
.survilience .imageContainer img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.survilience .imageContainer .partText {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  color: hsl(0, 0%, 93%);
  text-align: center;
  z-index: 100;
}
.survilience .imageContainer .partText h1 {
  font-size: 3rem;
  font-weight: 600;
}
.survilience .imageContainer .partText p {
  font-size: 1.3rem;
  padding: 0.5rem 0 2rem 0;
}
.survilience .content {
  margin: 4rem 6rem;
}
.survilience .content .contSec {
  display: grid;
  grid-template-columns: 0.9fr 1.1fr;
  align-items: start;
  justify-content: space-between;
  margin-top: 4.5rem;
}
.survilience .content .contSec img {
  width: 70%;
  height: 350px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 6px;
  box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
}
.survilience .content .contSec .contContainer {
  margin-top: 1rem;
  width: 100%;
  text-align: start;
}
.survilience .content .contSec .contContainer h1 {
  padding-bottom: 1rem;
}
.survilience .content .contSec .contContainer p {
  padding-bottom: 2rem;
  font-size: 20px;
}

@media screen and (max-width: 500px) {
  .survilience .overlay {
    position: absolute;
    height: 40vh;
    width: 100%;
    top: 0;
    bottom: 0;
    background: rgb(48, 61, 66);
    z-index: 1;
    mix-blend-mode: hard-light;
  }
  .survilience .imageContainer {
    top: 0;
    width: 100%;
    height: 40vh;
    position: relative;
  }
  .survilience .imageContainer .partText h1 {
    margin-top: 2rem;
    font-size: 28px;
  }
  .survilience .imageContainer .partText p {
    width: 80%;
    font-size: 18px;
    margin-left: 2rem;
  }
  .survilience .content {
    margin: 0 3rem;
    margin-bottom: 13rem;
  }
  .survilience .content .contSec {
    display: flex;
    flex-direction: column-reverse;
    width: 280px;
  }
  .survilience .content .contSec .imgContainer img {
    height: 75%;
    width: 100%;
  }
  .survilience .content .contSec .contContainer h1 {
    font-size: 25px;
  }
  .survilience .content .contSec .contContainer p {
    font-size: 18px;
  }
}/*# sourceMappingURL=survilience.css.map */