.header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: hsl(0, 0%, 93%);
  width: 100%;
  padding: 1rem;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(2, 15, 29, 0.904);
}
.header .logodiv {
  color: hsl(201, 33%, 16%);
}
.header .logodiv h1 span {
  color: rgb(12, 149, 208);
}
@media screen and (max-width: 768px) {
  .header .navBar {
    position: absolute;
    background: hsl(0, 0%, 93%);
    height: -moz-max-content;
    height: max-content;
    width: 80%;
    border-radius: 1rem;
    top: -500rem;
    left: 50%;
    transform: translate(-50%);
    z-index: 2000;
    box-shadow: 0 2px 4px rgba(2, 15, 29, 0.904);
    transition: 0.5s ease-in-out;
  }
  .header .navBar .navLists {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: auto;
    padding: 0.5rem 0;
  }
  .header .navBar .navLists .navItem {
    padding: 0.5rem 0;
  }
  .header .navBar .navLists .navItem .navLink {
    color: hsl(240, 4%, 36%);
    font-size: 0.9rem;
    font-weight: 600;
  }
  .header .navBar .closeNavbar {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    color: hsl(199, 100%, 33%);
  }
  .header .navBar .closeNavbar:hover {
    color: hsl(187, 85%, 43%);
  }
  .header .activeNavbar {
    top: 7rem;
  }
}
.header .toggleNavbar .icon {
  font-size: 25px;
  color: hsl(199, 100%, 33%);
}
.header .toggleNavbar:hover {
  color: hsl(187, 85%, 43%);
}

@media screen and (min-width: 769px) {
  .toggleNavbar, .closeNavbar {
    display: none;
  }
  .header {
    padding: 1.5rem 2rem;
  }
  .header .navBar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .header .navBar .navItem {
    display: inline-block;
  }
  .header .navBar .navItem .navLink {
    color: hsl(240, 4%, 36%);
    padding: 0 0.45rem;
    font-size: 0.9rem;
    font-weight: 500;
    display: block;
  }
  .header .navBar .navItem .navLink:hover {
    color: hsl(199, 100%, 33%);
  }
  .header .navBar .navItem .services-sub {
    width: 6rem;
    margin: 6px;
    top: 77px;
    display: none;
    position: relative;
    list-style: none;
    text-align: start;
  }
  .header .navBar .navItem .services-sub .submenu {
    cursor: pointer;
    background: hsl(0, 5%, 83%);
  }
  .header .navBar .navItem .services-sub .navLink1 {
    color: hsl(240, 4%, 36%);
    font-size: 0.9rem;
    font-weight: 600;
    display: block;
    width: 50%;
    height: 100%;
    padding: 16px;
  }
  .header .navBar .navItem:hover > .services-sub {
    display: block;
  }
}
@media screen and (min-width: 960px) {
  .header .navBar .navLists .navItem {
    padding: 0 0.7rem;
  }
}
@media screen and (min-width: 1240px) {
  .header {
    padding: 1rem 8.5rem;
  }
}/*# sourceMappingURL=navbar.css.map */