.network{
    width: 100%;
    //height: 260vh;
    .overlay{
        position: absolute;
        height: 40vh;
        width: 100%;
        top: 0;
        bottom: 0;
        background: rgb(48, 61, 66);
        z-index: 1;
        mix-blend-mode: hard-light;
    }
    .imageContainer{
        top: 0;
        width: 100%;
    height: 40vh;
    position: relative;


    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .partText{
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        color: hsl(0,0%,93%);
        text-align: center;
        z-index: 100;

        h1{
            font-size: 25px;
            font-weight: 600;
            //background: #fff;
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
        }
        p{
            font-size: 18px;
            //text-align: center;
            //color: #fff;
            margin: 0.5rem 1.5rem;
        }
    }}
    .secContent{
        justify-content: center;
        row-gap:2rem;
        margin-bottom: 10rem;
        //align-items: center;

        .singleDestination{
            margin-bottom: 30px;
            padding-bottom: 10px;
            border: 1px solid #ccc;
            height: 100%;
            display: grid;
            row-gap: 10px;
            //padding: 1rem;
            border-radius: 15px;
            align-items:flex-start;
            background: rgb(225,225,235);
            box-shadow: 0 2px 4px rgba(133, 133, 135, 0.549);
            overflow: hidden;
            //align-items: center;
            //justify-content: center;
            transition: 2s ease;
            

            &:hover{
                background: rgb(225,225,255);
                box-shadow: 1 4px 4px rgba(85, 85, 114, 0.549);
                transition: .3s ease;
                
            }

            .imageDiv{
                height: 180px;
                width: 100%;
                overflow: hidden;

                img{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    transition: 2s ease;
                }

                &:hover{
                    img{
                        transform: scale(1.1);
                    }
                }
            }

            .cardInfo{
                padding: 0rem;
                
                .title{
                    //padding-top: .5rem;
                    color: hsl(201,33%,16%);
                    font-size: 1.25rem;
                    text-align: center;
                    //padding-bottom: 1rem;
                }

                // .continent{
                //     gap: .5rem;

                //     .icon{
                //         color: hsl(240,4%,36%);
                //         font-size: 18px;
                //     }
                //     .name{
                //         color: hsl(240,4%,36%);
                //         font-weight: 400;
                //         font-size: 14px;
                //     }
                // }
            }

            .desc{
                text-align: center;
                font-size: 14px;
                color: hsl(240,4%,36%);
                //font-weight: 300;
                padding: 0 1rem;
                
            }
            .btn{
                margin-top: .1rem;
                margin-bottom: 1rem;
                color: hsl(0,0%,100%);
                font-weight: 600;
                justify-content: center;
                gap: .5rem;
                width: 50%;
                align-items: center;
                margin-left: 25%;
            }
            

        }
        
        
    }
    
    
}
@media screen and (min-width:840px) {
    .network {
        height:100%;
        //margin: 4rem 6rem;
        .overlay{
            position: absolute;
            height: 80vh;
            width: 100%;
            top: 0;
            bottom: 0;
            background: rgb(48, 61, 66);
            z-index: 1;
            mix-blend-mode: hard-light;
        }
        .imageContainer{
            top: 0;
            width: 100%;
        height: 80vh;
        position: relative;
        }
         .destination{
         margin: 4rem 6rem;
         text-align: center;
         
 
        h1{
         font-size: 3rem;
        }
        .first-des{
         display: flex;
         align-items: center;
         justify-content: space-between;
         margin-top: 4.5rem;
         
 
 
         .des-text{
             width: 45%;
             text-align: start;
             font-size: 1.1rem;
         
          h2{
             padding-bottom: 1rem;
          }
         //  p{
         //     margin: 1rem 0;
         //  }
         }
 
        }
        .first-des-reverse{
         display: flex;
         flex-direction: row-reverse;
         align-items: center;
         justify-content: space-between;
         margin-top: 4.5rem;
         
 
 
         .des-text{
             width: 45%;
             text-align: start;
             font-size: 1.1rem;
         
          h2{
             padding-bottom: 1rem;
          }
         }
 
        }
     }
     
     
     .image{
         position: relative;
         width: 50%;
         display: flex;
         justify-content: space-between;
         z-index: -99;
     
         img{
         width: 49%;
         height: 350px;
         object-fit: cover;
         border-radius: 6px;
         box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
 
         
         }
         img:nth-child(2){
             position: absolute;
             top: -10%;
             right: 0;
         }
     }
      
     .secContent{
         display: grid;
         grid-template-columns: repeat(3,1fr);
         margin: 4rem 6rem;
         column-gap: 2rem;
         
         .singleDestination{
             height: auto;
         }
     }}
 }