.service{
    .overlay{
        position: absolute;
        height: 80vh;
        width: 100%;
        top: 0;
        bottom: 0;
        background: rgb(48, 61, 66);
        z-index: 1;
        mix-blend-mode: hard-light;
    }
    .imageContainer{
        top: 0;
        width: 100%;
    height: 80vh;
    position: relative;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .partText{
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        color: hsl(0,0%,93%);
        text-align: center;
        z-index: 100;

        h1{
            font-size: 3rem;
            font-weight: 600;
            //background: #fff;
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
        }
        p{
            font-size: 1.3rem;
            
            //color: #fff;
            padding: 0.5rem 0 2rem 0;
        }
    }
}
.content{
    margin: 8rem 8rem;
    .contSec{
    display: flex;
    flex-direction: row-reverse;
    grid-template-columns: .9fr 1.1fr;
    gap: 80px;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
    img{
        width: 90%;
    height: 350px;
    object-fit: cover;
    border-radius: 6px;
    box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    }
    .contContainer{
        margin-top: 1rem;
        width: 60%;
        text-align: center;
        padding-left: 5rem;
        
        
        h1{
            padding-bottom: 1rem;
        }
        p{
            padding-bottom: 2rem;
            font-size: 20px;
            //width: 70%;
            
        }

    }

}
}
}

@media screen and (max-width:500px) {
    .service{

        .overlay{
            position: absolute;
            height: 40vh;
            width: 100%;
            top: 0;
            bottom: 0;
            background: rgb(48, 61, 66);
            z-index: 1;
            mix-blend-mode: hard-light;
        }
        .imageContainer{
            top: 0;
            width: 100%;
        height: 40vh;
        position: relative;
        .partText{
            h1{
                margin-top: 2rem;
                font-size: 30px;
            }
            p{
                width: 80%;
                font-size: 19px;
                margin-left: 2rem;
            }
        }
        }
        .content{
            width: 100%;
            //position: relative;
            left: 0;
            // right: 0;
            padding: 0;
             margin: 0rem;
            height: 110vh;
            margin-bottom: 4rem;
            .contSec{
                display: flex;
                 flex-direction: column-reverse;
                 //text-align: center;
        //         //justify-content: space-between;
                margin:  2rem 0 0;
                //width: 100%;
                left: 0;
                padding: 0;
                img{
                    margin-top: -3.5rem;
                    height: 250px;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 6px;
                    box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
                }
                .contContainer{
                   //margin-top: -2.5rem;
                   width: 100%;
                   margin-bottom:  0rem;
                   margin-right: 5rem;
                   height: max-content;
                    h1{
                        font-size: 26px;
                    }
                }

            }
        }
    }
    
}