.main {
  width: 100%;
}
.main .destination {
  margin: 0rem 2rem 1rem;
}
.main .destination p {
  margin-top: 1rem;
}
.main .destination .first-des {
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
  width: 250px;
}
.main .destination .first-des .des-text {
  width: 100%;
}
.main .destination .first-des .des-text h1 {
  font-size: 30px;
}
.main .destination .first-des .des-text p {
  margin-top: 1rem;
}
.main .destination .first-des .image {
  position: relative;
  width: 100%;
  margin: 3rem 0;
  display: flex;
  justify-content: space-between;
  z-index: -99;
}
.main .destination .first-des .image img {
  height: 180px;
  width: 49%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 6px;
  box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
}
.main .destination .first-des .image img:nth-child(2) {
  position: absolute;
  top: -10%;
  right: 0;
}
.main .destination .first-des-reverse {
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
}
.main .destination .first-des-reverse .des-text {
  width: 100%;
}
.main .destination .first-des-reverse .des-text p {
  margin-top: 1rem;
}
.main .destination .first-des-reverse .image {
  position: relative;
  width: 100%;
  margin: 3rem 0;
  display: flex;
  justify-content: space-between;
  z-index: -99;
}
.main .destination .first-des-reverse .image img {
  height: 180px;
  width: 49%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 6px;
  box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
}
.main .destination .first-des-reverse .image img:nth-child(2) {
  position: absolute;
  top: -10%;
  right: 0;
}
.main .secTitle .title {
  position: relative;
  width: -moz-max-content;
  width: max-content;
  color: hsl(240, 4%, 36%);
  margin: 2rem 0;
  z-index: 2;
  font-weight: 600;
  font-size: 30px;
}
.main .secContent {
  justify-content: center;
  gap: 1.8rem;
  margin-bottom: 10rem;
}
.main .secContent .singleDestination {
  margin-bottom: 20px;
  border: 1px solid #ccc;
  height: 100%;
  display: grid;
  row-gap: 10px;
  border-radius: 15px;
  align-items: flex-start;
  background: rgb(225, 225, 235);
  box-shadow: 0 2px 4px rgba(133, 133, 135, 0.549);
  overflow: hidden;
}
.main .secContent .singleDestination:hover {
  background: rgb(225, 225, 255);
  box-shadow: 1 4px 4px rgba(85, 85, 114, 0.549);
  transition: 0.3s ease;
}
.main .secContent .singleDestination .imageDiv {
  height: 180px;
  width: 100%;
  overflow: hidden;
}
.main .secContent .singleDestination .imageDiv img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: 2s ease;
}
.main .secContent .singleDestination .imageDiv:hover img {
  transform: scale(1.1);
}
.main .secContent .singleDestination .cardInfo {
  padding: 0.5rem;
}
.main .secContent .singleDestination .cardInfo .title {
  color: hsl(201, 33%, 16%);
  font-size: 1.25rem;
  text-align: center;
}
.main .secContent .singleDestination .desc {
  text-align: center;
  font-size: 14px;
  color: hsl(240, 4%, 36%);
  padding: 0 1rem;
}
.main .secContent .singleDestination .btn {
  margin-top: 0.1rem;
  margin-bottom: 1rem;
  color: hsl(0, 0%, 100%);
  font-weight: 600;
  justify-content: center;
  gap: 0.5rem;
  width: 50%;
  align-items: center;
  margin-left: 25%;
}
.main .hero .contain {
  height: 50vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(225, 225, 235);
  margin-bottom: 10rem;
}
.main .hero .contain .indicator {
  display: none;
}
.main .hero .contain .testimonial {
  position: relative;
  max-width: 900px;
  row-gap: 30px;
  margin-left: -4rem;
  width: 300px;
  overflow: hidden;
}
.main .hero .contain .testimonial .slide-row {
  display: flex;
  margin-left: 0rem;
  width: 80%;
}
.main .hero .contain .testimonial .slide-row .slide-col {
  margin-left: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
}
.main .hero .contain .testimonial .slide-row .slide-col .user-text {
  margin-top: 1rem;
  width: 240px;
  text-align: center;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 400;
  color: #333;
}
.main .hero .contain .testimonial .slide-row .slide-col img {
  height: 170px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}

@media screen and (min-width: 500px) {
  .title {
    font-size: 1.5rem;
  }
  .title::after {
    width: 155px !important;
  }
  .secContent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .secContent .singleDestination {
    height: auto;
  }
}
@media screen and (min-width: 840px) {
  .main .destination {
    margin: 1rem 4rem 6rem;
    text-align: center;
  }
  .main .destination h1 {
    font-size: 3rem;
  }
  .main .destination p {
    font-size: 1.1rem;
  }
  .main .destination .first-des {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 7rem;
  }
  .main .destination .first-des .des-text {
    width: 45%;
    text-align: start;
    font-size: 1.1rem;
  }
  .main .destination .first-des .des-text h2 {
    padding-bottom: 1rem;
  }
  .main .destination .first-des .image {
    position: relative;
    width: 50%;
    display: flex;
    justify-content: space-between;
    z-index: -99;
  }
  .main .destination .first-des .image img {
    width: 49%;
    height: 350px;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 6px;
    box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
  }
  .main .destination .first-des .image img:nth-child(2) {
    position: absolute;
    top: -10%;
    right: 0;
  }
  .main .destination .first-des-reverse {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
  }
  .main .destination .first-des-reverse .des-text {
    width: 45%;
    text-align: start;
    font-size: 1.1rem;
  }
  .main .destination .first-des-reverse .des-text h2 {
    padding-bottom: 1rem;
  }
  .main .destination .first-des-reverse .image {
    position: relative;
    width: 50%;
    display: flex;
    justify-content: space-between;
    z-index: -99;
  }
  .main .destination .first-des-reverse .image img {
    width: 49%;
    height: 350px;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 6px;
    box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
  }
  .main .destination .first-des-reverse .image img:nth-child(2) {
    position: absolute;
    top: -10%;
    right: 0;
  }
  .main .secContent {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 0.5rem;
  }
  .main .secContent .singleDestination {
    height: auto;
  }
  .main .hero {
    width: 100%;
    height: 90vh;
    background: rgb(225, 225, 235);
    border-radius: 1.5rem;
  }
  .main .hero h1 {
    text-align: center;
    padding-top: 40px;
    font-size: 40px;
    color: hsl(201, 33%, 16%);
  }
  .main .hero .bton {
    display: none;
  }
  .main .hero .contain {
    width: 800px;
    height: 400px;
    position: relative;
    margin-top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .main .hero .contain .indicator {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -40px;
    background: #1e1a1a;
    border-radius: 7rem;
    width: 4rem;
    align-items: center;
    opacity: 0.8;
    display: block;
  }
  .main .hero .contain .indicator .btn {
    display: inline-block;
    width: 1px;
    margin-top: 2px;
    margin-left: 4px;
    margin-right: 0px;
    margin-bottom: 2px;
    padding-right: 0rem;
    border-radius: 15px;
    padding-top: 0rem;
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
    background: rgb(143, 154, 171);
    cursor: pointer;
    transition: 0.4s;
  }
  .main .hero .contain .indicator .active {
    width: 20px;
    background: beige;
  }
  .main .hero .contain .testimonial {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .main .hero .contain .testimonial .slide-row {
    display: flex;
    width: 3200px;
    transition: 0.5s;
  }
  .main .hero .contain .testimonial .slide-row .slide-col {
    width: 800px;
    height: 400px;
    position: relative;
  }
  .main .hero .contain .testimonial .slide-row .slide-col .user-img {
    position: absolute;
    top: 2.5rem;
    right: 0;
    left: 30rem;
    height: 100%;
  }
  .main .hero .contain .testimonial .slide-row .slide-col .user-img img {
    height: 90%;
    border-radius: 10px;
  }
  .main .hero .contain .testimonial .slide-row .slide-col .user-text {
    background: rgb(85, 83, 83);
    width: 520px;
    height: 270px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 10px;
    color: antiquewhite;
    padding: 45px;
    box-sizing: border-box;
    z-index: 2;
  }
  .main .hero .contain .testimonial .slide-row .slide-col .user-text p {
    font-size: 18px;
    line-height: 24px;
  }
  .main .hero .contain .testimonial .slide-row .slide-col .user-text h3 {
    margin: 35px 0 5px;
    color: aliceblue;
  }
}/*# sourceMappingURL=main.css.map */