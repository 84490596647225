.footer {
  width: 100%;
  position: relative;
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 100%;
}
.footer .imageDiv {
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
}
.footer .imageDiv .img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.footer .secContent {
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  padding: 2rem initial;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  row-gap: 2rem;
  z-index: 100;
}
.footer .secContent .contactDiv {
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
}
.footer .secContent .contactDiv .text small {
  font-size: 13px;
  font-weight: 400;
  color: hsl(0, 0%, 100%);
}
.footer .secContent .contactDiv .text h2 {
  color: hsl(0, 0%, 100%);
  font-size: 1.8rem;
}
.footer .secContent .contactDiv .inputDiv {
  width: 100%;
  gap: 1rem;
  flex-direction: column;
}
.footer .secContent .contactDiv .inputDiv input {
  width: 100%;
  padding: 0.5rem 0.6rem;
  border-radius: 3rem;
  border: 1px solid hsl(0, 0%, 100%);
  outline: none;
  background: transparent;
  color: hsl(0, 0%, 100%);
}
.footer .secContent .contactDiv .inputDiv input::-moz-placeholder {
  color: hsl(0, 0%, 100%);
  opacity: 0.5rem;
}
.footer .secContent .contactDiv .inputDiv input::placeholder {
  color: hsl(0, 0%, 100%);
  opacity: 0.5rem;
}
.footer .secContent .contactDiv .inputDiv .btn {
  width: 100%;
  justify-content: center;
  color: hsl(0, 0%, 100%);
  font-weight: 500;
  gap: 0.5rem;
}
.footer .secContent .contactDiv .inputDiv .btn .icon {
  font-size: 18px;
}
.footer .secContent .footerCard {
  position: absolute;
  padding: 0rem 1rem 0rem;
  margin-bottom: 1rem;
  gap: 1rem;
  border-radius: 0rem;
  background: black;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
}
.footer .secContent .footerCard .footerIntro {
  flex-basis: 100%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 0.5rem;
  padding-top: 1rem;
}
.footer .secContent .footerCard .footerIntro .logoDiv .logo {
  color: antiquewhite;
  font-weight: 600;
  display: block;
  font-size: 19px;
}
.footer .secContent .footerCard .footerIntro .logoDiv .logo .icon {
  font-size: 25px;
  color: hsl(199, 100%, 33%);
  margin-right: 10px;
}
.footer .secContent .footerCard .footerIntro .footerParagraph p {
  font-size: 15px;
  color: antiquewhite;
  width: 90%;
}
.footer .secContent .footerCard .footerIntro .footerSocials {
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}
.footer .secContent .footerCard .footerIntro .footerSocials .icon {
  color: antiquewhite;
  font-size: 20px;
}
.footer .secContent .footerCard .footerIntro .footerSocials .icon:hover {
  color: hsl(199, 100%, 33%);
}
.footer .secContent .footerCard .footerLinks {
  display: flex;
  flex-direction: row;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  padding-top: 1rem;
  margin-bottom: 2.5rem;
  flex-basis: 50%;
  width: 100%;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
}
.footer .secContent .footerCard .footerLinks .linkGroup .groupTitle {
  display: block;
  font-weight: 600;
  color: antiquewhite;
  margin-bottom: 0.5rem;
}
.footer .secContent .footerCard .footerLinks .linkGroup .footerList {
  font-size: 15px;
  color: antiquewhite;
  transition: 0.3s ease-in-out;
}
.footer .secContent .footerCard .footerLinks .linkGroup .footerList .icon {
  font-size: 15px;
  color: antiquewhite;
}
.footer .secContent .footerCard .footerLinks .linkGroup .footerList:hover {
  color: hsl(199, 100%, 33%);
  transform: translateX(7px);
}
.footer .secContent .footerCard .footerLinks .linkGroup .footerList:hover .icon {
  color: hsl(187, 85%, 43%);
}
.footer .secContent .footerCard .footerLinks .linkGroup1 {
  margin-right: 0rem;
}
.footer .secContent .footerCard .footerLinks .linkGroup1 .groupTitle {
  display: block;
  font-weight: 600;
  color: antiquewhite;
  margin-bottom: 0.5rem;
}
.footer .secContent .footerCard .footerLinks .linkGroup1 .footerList1 {
  font-size: 15px;
  color: antiquewhite;
}
.footer .secContent .footerCard .footerLinks .linkGroup1 .footerList1 p {
  margin-bottom: 0.3rem;
}
.footer .secContent .footerCard .footerDiv {
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: hsl(189, 15%, 25%);
  padding: 0.5rem 2rem;
}
.footer .secContent .footerCard .footerDiv small {
  font-size: 10px;
  color: hsl(0, 0%, 100%);
}

@media screen and (min-width: 500px) {
  .footer .secContent .footerCard .footerLinks {
    grid-template-columns: repeat(3, 1fr);
    padding-bottom: 1rem;
  }
  .footer .secContent .footerCard .footerDiv {
    text-align: center;
  }
  .footer .secContent .footerCard .footerDiv small {
    font-size: 14px;
    color: hsl(0, 0%, 100%);
  }
}
@media screen and (min-width: 660px) {
  .footer .secContent .contactDiv {
    flex-direction: row;
    justify-content: space-between;
  }
  .footer .secContent .contactDiv .inputDiv {
    width: -moz-max-content;
    width: max-content;
    flex-direction: row;
    justify-content: flex-start;
  }
  .footer .secContent .contactDiv .inputDiv input {
    width: 60%;
  }
  .footer .secContent .contactDiv .inputDiv .btn {
    width: -moz-max-content;
    width: max-content;
  }
}
@media screen and (min-width: 840px) {
  .footer {
    padding: 5rem 0;
  }
  .footer .secContent .footerCard {
    flex-direction: row;
  }
  .footer .secContent .footerCard .footerLinks {
    grid-template-columns: repeat(3, 1fr);
    padding-bottom: 1rem;
  }
}
@media screen and (min-width: 1024px) {
  .footer .secContent .footerCard {
    padding: 0rem 1rem;
    margin-top: 100px;
  }
  .footer .secContent .footerCard .footerIntro .footerParagraph {
    font-size: 15px;
    color: hsl(240, 4%, 36%);
  }
  .footer .secContent .footerCard .footerIntro .footerSocials {
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem;
  }
  .footer .secContent .footerCard .footerIntro .footerSocials .icon {
    color: hsl(240, 4%, 36%);
    font-size: 25px;
  }
  .footer .secContent .footerCard .footerIntro .footerSocials .icon:hover {
    color: hsl(199, 100%, 33%);
  }
  .footer .secContent .footerCard .footerLinks .linkGroup .footerList {
    font-size: 16px;
    padding: 5px 0;
  }
  .footer .secContent .footerCard .footerLinks .linkGroup1 {
    margin-right: 4rem;
  }
  .footer .secContent .footerCard .footerLinks .linkGroup1 .groupTitle {
    margin-bottom: 0.8rem;
  }
}/*# sourceMappingURL=Footer.css.map */