.hotel {
  width: 100%;
}
.hotel .contain h1 {
  color: hsl(201, 33%, 16%);
  margin-top: 10px;
  text-align: center;
}
.hotel .contain .image {
  margin-top: 20px;
  box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
}
.hotel .contain .text {
  margin-top: 1rem;
  font-size: 20px;
}
.hotel .mainCon .secTitle {
  margin: 2rem 0;
}
.hotel .mainCon .secTitle .title {
  position: relative;
  width: -moz-max-content;
  width: max-content;
  color: hsl(240, 4%, 36%);
  margin: 2rem 0;
  z-index: 2;
  font-weight: 600;
  font-size: 30px;
}

@media screen and (max-width: 500px) {
  .hotel {
    width: 100%;
    margin-right: 2rem;
    margin-left: 0rem;
  }
  .hotel .mainCon {
    margin-left: 0rem;
    margin-right: 5rem;
    margin-bottom: 11rem;
  }
  .hotel .mainCon .grid {
    row-gap: 0rem;
  }
  .hotel .mainCon .grid .card {
    margin-left: 3rem;
    height: 60%;
    width: 100%;
  }
  .hotel .mainCon .grid .card .cardM {
    height: 50%;
    width: 100%;
  }
}/*# sourceMappingURL=hotel.css.map */