.survilience{
    .overlay{
        position: absolute;
        height: 80vh;
        width: 100%;
        top: 0;
        bottom: 0;
        background: rgb(48, 61, 66);
        z-index: 1;
        mix-blend-mode: hard-light;
    }
    .imageContainer{
        top: 0;
        width: 100%;
    height: 80vh;
    position: relative;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .partText{
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        color: hsl(0,0%,93%);
        text-align: center;
        z-index: 100;

        h1{
            font-size: 3rem;
            font-weight: 600;
            //background: #fff;
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
        }
        p{
            font-size: 1.3rem;
            
            //color: #fff;
            padding: 0.5rem 0 2rem 0;
        }
    }
}
.content{
    margin: 4rem 6rem;
    .contSec{
    display: grid;
    grid-template-columns: .9fr 1.1fr;
    //gap: 0px;
    align-items: start;
    justify-content: space-between;
    margin-top: 4.5rem;
    img{
        width: 70%;
    height: 350px;
    object-fit: cover;
    border-radius: 6px;
    box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    }
    .contContainer{
        margin-top: 1rem;
        width: 100%;
        text-align: start;
        
        h1{
            padding-bottom: 1rem;
        }
        p{
            padding-bottom: 2rem;
            font-size: 20px;
        }

    }

}
}
}

@media screen and (max-width:500px) {
    .survilience{
        //width: 310px;
        .overlay{
            position: absolute;
            height: 40vh;
            width: 100%;
            top: 0;
            bottom: 0;
            background: rgb(48, 61, 66);
            z-index: 1;
            mix-blend-mode: hard-light;
        }
        .imageContainer{
            top: 0;
            width: 100%;
        height: 40vh;
        position: relative;
        .partText{
            h1{
                margin-top: 2rem;
                font-size: 28px;
            }
            p{
                width: 80%;
                font-size: 18px;
                margin-left: 2rem;
            }
        }
        }
        .content{
            margin: 0  3rem;
            margin-bottom: 13rem;
            .contSec{
                display: flex;
                flex-direction: column-reverse;
                width: 280px; 
                .imgContainer{
                    // height: 75%;
                    //      width: 250px;
                    img{
                        height: 75%;
                         width: 100%;
                    }
                }
                .contContainer{
                    h1{
                        font-size: 25px;
                    }
                    p{
                        font-size: 18px;
                    }
                }
            }

            // .partText{
            //     margin: 0  3rem;
            //     margin-bottom: 13rem;
                
            //     .first{
            //         display: flex;
            //         flex-direction: column-reverse;
            //         width: 250px;

            //         .contain{
            //             width: 280px;
            //             h1{
            //                 font-size: 25px;
            //                 margin-top: 1rem;
            //             }
            //             p{
            //                 font-size: 18px;
            //             }
            //         }
            //         img{
            //             height: 75%;
            //             width: 100%;
            //         }
            //     }
                
            // }
        }
    }
}