*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family:sans-serif;
}
html{
  scroll-behavior: smooth;
}
a{
  text-decoration: none;
}
li{
  list-style: none;
}
.section{
  padding: 4rem 0 2rem;
}
.container{
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.icon{
  font-size: 2rem;
  cursor: pointer;
}
.flex{
  display: flex;
  align-items: center;
}

.grid{
  display: grid;
  align-items: center;
  /* display: grid; */
        grid-template-columns: repeat(3,1fr);
}
.btn{
  padding: 0.6rem 1.5rem;
  background: linear-gradient(to right,hsl(187,85%,43%),hsl(199,100%,33%));
  border-radius: 3rem;
  border: none;
  outline: none;
  cursor: pointer;
}
.btn a{
  color: hsl(0,0%,100%);
  font-weight: 500;
}
.btn:hover{
  background: hsl(187,85%,43%);
}
img,video{
  width: 100%;
  height: auto;
}
input{
  font-size: 100%;
}
body{
  background: rgb(240,240,246);
}

@media screen and (min-width:1240px) {
  .container{
    width: 80% !important;
    margin: auto !important;
  }

  .section{
    padding: 6rem 0.4rem;
  }
}

@media screen and (min-width:2560px) {
  body{
    zoom: 1.7;
  }
}

.css-1d1r5q-MuiFormHelperText-root {
  color: red !important;
}