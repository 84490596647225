.main{
    width: 100%;
    .destination{
        margin: 0rem 2rem 1rem;
        h1{
           // text-align: center;
        }
        p{
            margin-top: 1rem;
            //text-align: center;
        }

        .first-des{
            display: flex;
            flex-direction: column;
            //align-items: center;
            //justify-content: space-between;
            margin-top: 2.5rem;
            width: 250px;
            .des-text{
                width: 100%;
                h1{
                    font-size: 30px;
                }
                p{
                    margin-top: 1rem;
                }
            }
            .image{ 
                position: relative;                                            
                width: 100%;
                margin: 3rem 0;
                display: flex;
                justify-content: space-between;
                z-index: -99;
                img{
                    height: 180px;
                    width: 49%;
                    object-fit: cover;
                    border-radius : 6px;
                    box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
                }
                img:nth-child(2){
                    position: absolute;
                    top: -10%;
                    right: 0;
                }
            }

        }
        .first-des-reverse{
            display: flex;
            flex-direction: column;
            //align-items: center;
            //justify-content: space-between;
            margin-top: 2.5rem;

            .des-text{
                width: 100%;
                p{
                    margin-top: 1rem;
                }
            }
            .image{ 
                position: relative;                                            
                width: 100%;
                margin: 3rem 0;
                display: flex;
                justify-content: space-between;
                z-index: -99;
                img{
                    height: 180px;
                    width: 49%;
                    object-fit: cover;
                    border-radius: 6px;
                    box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
                }
                img:nth-child(2){
                    position: absolute;
                    top: -10%;
                    right: 0;
                }
            }

        }
    }
    
    .secTitle{
        .title{
            position: relative;
            width: max-content;
            color: hsl(240,4%,36%);
            margin: 2rem 0;
            z-index: 2;
            font-weight: 600;
            font-size: 30px;

            // &::after{
            //     position: absolute;
            //     content:'';
            //     background: hsl(187,85%,43%);
            //     height: 5px;
            //     width: 10px;
            //     right: 0;
            //     bottom: 5px;
            //     z-index:-1;
            // }
        }
    }

    .secContent{
        justify-content: center;
        gap:1.8rem;
        margin-bottom: 10rem;
        //align-items: center;

        .singleDestination{
            margin-bottom: 20px;
            border: 1px solid #ccc;
            height: 100%;
            display: grid;
            row-gap: 10px;
            //padding: 1rem;
            border-radius: 15px;
            align-items: flex-start;
            background: rgb(225,225,235);
            box-shadow: 0 2px 4px rgba(133, 133, 135, 0.549);
            overflow: hidden;
            //align-items: center;
            //justify-content: center;

            &:hover{
                background: rgb(225,225,255);
                box-shadow: 1 4px 4px rgba(85, 85, 114, 0.549);
                transition: .3s ease;
            }

            .imageDiv{
                height: 180px;
                width: 100%;
                overflow: hidden;

                img{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    transition: 2s ease;
                }

                &:hover{
                    img{
                        transform: scale(1.1);
                    }
                }
            }

            .cardInfo{
                padding: .5rem;
                
                .title{
                    color: hsl(201,33%,16%);
                    font-size: 1.25rem;
                    text-align: center;
                }

                // .continent{
                //     gap: .5rem;

                //     .icon{
                //         color: hsl(240,4%,36%);
                //         font-size: 18px;
                //     }
                //     .name{
                //         color: hsl(240,4%,36%);
                //         font-weight: 400;
                //         font-size: 14px;
                //     }
                // }
            }

            .desc{
                text-align: center;
                font-size: 14px;
                color: hsl(240,4%,36%);
                //font-weight: 300;
                padding: 0 1rem;
                
            }
            .btn{
                margin-top: .1rem;
                margin-bottom: 1rem;
                color: hsl(0,0%,100%);
                font-weight: 600;
                justify-content: center;
                gap: .5rem;
                width: 50%;
                align-items: center;
                margin-left: 25%;
            }

        }
    }
    .hero{
        // .bton{
        //     position: relative;    
        //     transform: translateY(-50%);
        //     cursor: pointer;
        //     top: 13rem;
        // }
        // #left{
        //     left: .5rem;
        // }
        // #right{
        //     left: 17rem;
        // }
        .contain{
            height: 50vh;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgb(225,225,235);
            margin-bottom: 10rem;
            
            .indicator{
                display: none;
            }
            .testimonial{
                position: relative;
                max-width: 900px;
                //width: 100%;
                //padding: 50px;
                row-gap: 30px;
                margin-left: -4rem;
                width: 300px;
                overflow: hidden;
                .slide-row{
                    //width: 300px;
                    display: flex;
                    margin-left: 0rem;
                    width: 80%;
                    // SwiperSlide{

                    // }
                    .slide-col{
                        //width: 300px;
                        margin-left: 4rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column-reverse;

                        .user-text{
                            margin-top: 1rem;
                            width: 240px;
                            text-align: center;
                            padding: 0 5px;
                            font-size: 14px;
                            font-weight: 400;
                            color: #333;
                        }
                        img{
                             height: 170px;
                            // width: 170px;
                             object-fit: cover;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width:500px) {
    .title{
        font-size: 1.5rem;
        &::after{
            width: 155px !important;
        }
    }
    .secContent{
        display: grid;
        //grid-template-columns: repeat(3, 1fr); /* Creates three equal-width columns */
        //gap: 20px;
        grid-template-columns: repeat(2,1fr);

        .singleDestination{
            height: auto;
        }
    }
    
}

@media screen and (min-width:840px) {
   .main {
        .destination{
        margin: 1rem 4rem 6rem;
        text-align: center;
    

       h1{
        font-size: 3rem;
       }
       p{
        font-size: 1.1rem;

       }
       .first-des{
        width: 100%;
        
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 7rem;
        


        .des-text{
            width: 45%;
            text-align: start;
            font-size: 1.1rem;
        
         h2{
            padding-bottom: 1rem;
         }
        //  p{
        //     margin: 1rem 0;
        //  }
        }
        .image{
            position: relative;
            width: 50%;
            display: flex;
            justify-content: space-between;
            z-index: -99;
        
            img{
            width: 49%;
            height: 350px;
            object-fit: cover;
            border-radius: 6px;
            box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    
            
            }
            img:nth-child(2){
                position: absolute;
                top: -10%;
                right: 0;
            }
        }

       }
       .first-des-reverse{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        margin-top: 4.5rem;
        


        .des-text{
            width: 45%;
            text-align: start;
            font-size: 1.1rem;
        
         h2{
            padding-bottom: 1rem;
         }
        }
        .image{
            position: relative;
            width: 50%;
            display: flex;
            justify-content: space-between;
            z-index: -99;
        
            img{
            width: 49%;
            height: 350px;
            object-fit: cover;
            border-radius: 6px;
            box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    
            
            }
            img:nth-child(2){
                position: absolute;
                top: -10%;
                right: 0;
            }
        }

       }
    }
    
    
    
     
    .secContent{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        row-gap: .5rem;
        .singleDestination{
            height: auto;
        }
    }
    .hero{
        width: 100%;
        height: 90vh;
        background: rgb(225,225,235);
        border-radius: 1.5rem;
        h1{
            text-align: center;
            padding-top: 40px;
            font-size: 40px;
            color: hsl(201,33%,16%);
        }
        .bton{
            display: none;
        }
        .contain{
            width: 800px;
            height: 400px;
            position: relative;
            margin-top: 20%;
            left: 50%;
            transform: translate(-50%,-50%);
            
            // .right{
            //     display: none;
            // }
            .indicator{
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -40px;
                background: #1e1a1a;
                border-radius: 7rem;
                width: 4rem;
                align-items: center;
                opacity: .8;
                display: block;
                //margin: 2rem;
                .btn{
                    display: inline-block;
                    //height: 15px;
                    
                    width: 1px;
                    //margin:2px 2px 1.5px;
                    margin-top: 2px;
                    margin-left: 4px;
                    margin-right: 0px;
                    margin-bottom: 2px;
                    padding-right: 0rem;
                    border-radius: 15px;
                    padding-top: 0rem;
                    padding-left: .5rem;
                    padding-bottom: .5rem;
                    background: rgb(143, 154, 171);
                    cursor: pointer;
                    transition: .4s;
                    // &:hover{
                    //     color: aliceblue;
                    // }
                }
                .active{
                    width: 20px;
                    background: beige;
                }
            }
            .testimonial{
                width: 100%;
                height: 100%;
                overflow: hidden;
               
                .slide-row{
                    display: flex;
                    width: 3200px;
                    transition: .5s;
                    .slide-col{
                        width: 800px;
                        height: 400px;
                        position: relative;
                .user-img{
                    position: absolute;
                    top: 2.5rem;
                    right: 0;
                    left: 30rem;
                    height: 100%;
                    img{
                        height: 90%;
                        border-radius: 10px;
                    }
                }
                .user-text{
                    background: rgb(85, 83, 83);
                    width: 520px;
                    height: 270px;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    border-radius: 10px;
                    color: antiquewhite;
                    padding: 45px;
                    box-sizing: border-box;
                    z-index: 2;
                    p{
                        font-size: 18px;
                        line-height: 24px;
                    }
                    h3{
                        margin: 35px 0 5px;
                        color: aliceblue;
                    }
                }
            }
        }}}
    }
}
}