.partners{
    .overlay{
        position: absolute;
        height: 80vh;
        width: 100%;
        top: 0;
        bottom: 0;
        background: rgb(48, 61, 66);
        z-index: 1;
        mix-blend-mode: hard-light;
    }
    .imageContainer{
        width: 100%;
    height: 80vh;
    position: relative;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .partText{
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        color: hsl(0,0%,93%);
        text-align: center;
        z-index: 100;

        h1{
            font-size: 3rem;
            font-weight: 600;
            //background: #fff;
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
        }
        p{
            font-size: 1.3rem;
            
            //color: #fff;
            padding: 0.5rem 0 2rem 0;
        }
    }}
    .aboutContainer {
        .partText{
            margin: 4rem 6rem;
            .first{
                display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 4.5rem;
        .contain{
            width: 45%;
            text-align: center;
            h1{
            padding-bottom: 1rem;
        }
        p{
            padding-bottom: 2rem;
            font-size: 20px;
        }

        }
        img{
            width: 50%;
        height: 350px;
        object-fit: cover;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
        }
     }
     .second{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        margin-top: 4.5rem;
        
        .contain{
            width: 45%;
            text-align: center;
            h1{
            padding-bottom: 1rem;
        }
        p{
            padding-bottom: 2rem;
            font-size: 20px;
        }

        }
        img{
            width: 50%;
        height: 350px;
        object-fit: cover;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
        }
     }
     .third{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 4.5rem;
        
        .contain{
            width: 45%;
            text-align: center;
            h1{
            padding-bottom: 1rem;
        }
        p{
            padding-bottom: 2rem;
            font-size: 20px;
        }

        }
        img{
            width: 50%;
        height: 350px;
        object-fit: cover;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
        }
     }
    }
    }

}
@media screen and (max-width:550px) {
    .partners
    {
        //height: 410vh;
        //width: min-content;
        .overlay{
            position: absolute;
            height: 40vh;
            width: 100%;
            top: 0;
            bottom: 0;
            background: rgb(48, 61, 66);
            z-index: 1;
            mix-blend-mode: hard-light;
        }
        .imageContainer{
            top: 0;
            width: 100%;
        height: 40vh;
        position: relative;
        .partText{
            h1{
                margin-top: 2rem;
                font-size: 30px;
            }
            p{
                width: 80%;
                font-size: 19px;
                margin-left: 2rem;
            }
        }
        }
        .aboutContainer{
            margin: 0 2rem;
            margin-bottom: 13rem;
            .partText{
            .first{
                margin-top: -3rem;
                display: flex;
                flex-direction: column;
                //width: 360px;
                .contain{
                    width: 330px;
                    text-align: center;
                    h1{
                        margin-top: 20%;
                        padding: 10px 20px ;
                      }
            
                      p{
                        font-size: 1.1rem;
                        padding: 0 0 2rem 0;
                      }
                }
                img{
                    width: 270px;
                }
            }
            .second{
                margin-top: -2rem;
                display: flex;
                flex-direction: column;
                //width: 360px;
                .contain{
                    width: 330px;
                    h1{
                        margin-top: 20%;
                        padding: 10px 20px ;
                      }
            
                      p{
                        font-size: 1.1rem;
                        padding: 0 0 2rem 0;
                      }
                }
                img{
                    width: 270px;
                }
            }
            .third{
                margin-top: -2rem;
                display: flex;
                flex-direction: column;
                //width: 360px;
                .contain{
                    width: 330px;
                    h1{
                        margin-top: 20%;
                        padding: 10px 20px ;
                      }
            
                      p{
                        font-size: 1.1rem;
                        padding: 0 0 2rem 0;
                      }
                }
                img{
                    width: 270px;
                }
            }
          
        }}
    }
}